const About = () => {
  return (
    <section className='flex flex-col gap-y-3'>
      <p className="font-semibold text-lg">
        EX NURSE
      </p>
      <p className="font-semibold text-lg">
        FRONTEND ENGINEER + DESIGN.
      </p>
      <p className="font-semibold text-lg">WORKING @<a href="https://www.hema.to/" target="_blank" rel="noopener">hema.to</a></p>
      <p className="font-semibold text-lg">
        LIVING IN 53.57532° N,  10.01534° E
        <br />
        BORN AND RAISE IN 35.16666° N, 129.06666° E
      </p>
    </section>
  )
}

export default About