export const projects = [
  {
    year:'2020 - 2023',
    name: 'Ramp medical',
    summary:'Building EMR and CDS tools for health projessions.',
    link:'https://rampmedical.com/'
  },
  {
    year:'2023',
    name:'Miniflux binder',
    summary:'Generating E-pub files for wireless RSS feed reading with Miniflux API.',
    link:'https://www.minifluxbinder.com/'
  },
  {
    year:'2023',
    name:'Hack your fridge',
    summary:'Finding recipes via utilising Yammly API. ',
    link:'https://hack-your-fridge.vercel.app/'
  },
  {
    year:'2023',
    name:'D3 project',
    summary:'Generating graphs with D3.js',
    link:'https://visualisations-one.vercel.app/'
  },
  {
    year:'2024',
    name: 'Activity tracker',
    summary:'Providing activities’ analysis with Strava API.',
    link:'https://activity-visualasation.vercel.app/'
  },
]